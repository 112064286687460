import React from 'react'
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import Gallery from '../components/Gallery'
import Layout from "../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const Drawings = ({ data }) => (
  <Layout> 
    <div>
      <Gallery works={data.allContentfulAfbeelding.edges}/>
    </div>
  </Layout>
)

Drawings.propTypes = propTypes;

export default Drawings;

export const query = graphql`
query {
  allContentfulAfbeelding
  (filter: {type: {eq:"Tekening"}})
  {
    edges {
      node {
        year
        title
        description {
          description
        }
        
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }

          gatsbyImageData(
            width: 600
            layout: CONSTRAINED
            jpegProgressive: true
            placeholder: DOMINANT_COLOR
            quality: 60
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}    
`